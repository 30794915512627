<template>
  <div class="container">
    <el-dialog title="批量操作" width='1000px' v-model="dialogVisible">
        <el-button type="primary" @click="addTags">添加标签</el-button>
        <div class="wrap">
          <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="TagGroupName" label="标签组名称"></el-table-column>
              <el-table-column prop="TagName" label="标签名称">
                <template #default="scope">
                  <div class="cell-wrap">
                    <el-tag  effect="plain" class="tag-cell" v-if="scope.row.TagName!='无需标签'">{{scope.row.TagName}}</el-tag>
                    <div v-else-if="scope.row.TagName=='无需标签'">无需标签</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="GroupList" label="关联群聊">
                <template #default="scope">
                  <div class="cell-wrap">
                      <div v-for="(item,index) in scope.row.GroupList" :key="index">
                          <p>{{ index == scope.row.GroupList.length-1 && item.GroupName || (item.GroupName + '、') }}</p>
                      </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180">
                <template #default="scope">
                  <div class="cell-wrap">
                    <el-button class="edit-group" type="primary" @click="editGroup(scope.row)">编辑群聊</el-button>
                    <el-button type="primary" @click="delAction(scope.row)" v-if="scope.row.TagID!=-1">删除</el-button>
                  </div>
                </template>
            </el-table-column>
          </el-table>
        </div>
      
        <template #footer>
              <div class="dialog-footer">
                 <el-button type="primary" @click="submitForm">确 定</el-button>
                 <el-button @click="submitForm">取 消</el-button>
              </div>
          </template>
    </el-dialog>
      <AddTagField ref="addTagsFieldRef" @onConfirm="tagsAction" />
      <GroupAddSelectCop ref="groupSelectDialog" @onConfirm="chooseGroupAction" />
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref } from "vue";
import { ElMessageBox,ElMessage } from 'element-plus';
import {PromotionTaskGetTagRule,PromotionTaskDeleteTagRule} from "@/helper/promotion";
import AddTagField from '@/components/GroupAddManager/AddTagField'
import GroupAddSelectCop from "@/components/GroupAddManager/GroupAddSelectCop";
import _ from "lodash";
export default {
  name: "AddTagCop",
    components: {
      AddTagField,
      GroupAddSelectCop
    },
  setup(props, context) {
    const dialogVisible = ref(false);
    const addTagsFieldRef=ref(null)
    const groupSelectDialog=ref(null)
    const tableData=ref([])
    const robotNo=ref("")
    onMounted(()=>{})
    
    function initCop(sid) {
      dialogVisible.value = true;
      robotNo.value=sid
      searchAction()
    }

    function searchAction(){
      PromotionTaskGetTagRule({RobotSerialNo:robotNo.value}).then(res=>{
        console.log(res);
        tableData.value=res.List || []
        
      })
    }

    //打开标签
    function addTags(){
      addTagsFieldRef.value.initCop(1,robotNo.value)
    }
    function tagsAction(){
      searchAction()
    }
    //编辑群聊
    function editGroup(row){
      groupSelectDialog.value.initCop(0,row.GroupList,robotNo.value,row.TagID);
    }
    //确认群聊
    function chooseGroupAction(){
      searchAction()
    }
    //删除
    function delAction(item){
      let param={
          WTagID:item.TagID,
          RobotSerialNo:robotNo.value
      }
       ElMessageBox.confirm("删除后该规则后将失效，是否确认删除？", '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          PromotionTaskDeleteTagRule(param).then(res=>{
            ElMessage({
              type: 'success',
              message: '删除成功!',
            });
            searchAction()
          })
        }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消',
            });
        });
    }
    /*****************点击事件*********************/
     //确定
     function submitForm(){
       dialogVisible.value = false
       context.emit("onConfirm");
     }
    return {
      initCop,
      dialogVisible,
      submitForm,
      addTags,
      addTagsFieldRef,
      tableData,
      editGroup,
      groupSelectDialog,
      delAction,
      chooseGroupAction,
      searchAction,
      robotNo,
      tagsAction
    };
  }
};
</script>
<style lang='scss' scoped>
.cell-wrap{
  display: flex;
  align-items: center;
}
.wrap{
    width: 100%;
    max-height: 400px;
    overflow: auto;
    margin-top: 10px;
}
.tag-cell{
  color:$color-common !important;
  border-color: $color-common !important;
  background-color: #fff !important;
  border-radius: 10px;

}
</style>
<template>
  <div class="container">
    <el-dialog
      :title="'请选择' + dialogTitle"
      width="800px"
      v-model="dialogVisible"
    >
      <div class="wrap-container">
        <div class="wrap">
          <div class="left-header">
            <el-input
              placeholder="请输入名称"
              prefix-icon="el-icon-search"
              v-model="searchObj.key"
              style="width: 160px"
              @change="changeName"
              :disabled="!canEdit"
            ></el-input>
            <div
              class="header-group"
              @click="groupFieldAction"
              v-if="type == 0 || type == 3"
            >
              <div>
                <el-icon :size="14" :color="'#999'">
                  <Filter />
                </el-icon>

                <div>
                  {{ searchObj.tagStr == "" ? "全部标签" : searchObj.tagStr }}
                </div>
              </div>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <div class="body-wrap">
            <div
              class="cell"
              v-for="(item, index) in groupList"
              :key="index"
              @click="selectAction(index)"
            >
              <div class="cell-l">
                <img src="@/assets/imgs/group_default.jpg" v-if="(type == 0 || type == 3)&&!item.ChatRoomHeadImg"/>
                <img src="@/assets/imgs/default_user.png" alt="" v-else-if="(type == 1|| type == 2)&&!item.ChatRoomHeadImg">
                <img :src="item.ChatRoomHeadImg" v-else/>
                <div class="title" v-if="type == 1 || type == 2">
                  {{ item.NickName }}
                </div>
                <div class="title" v-else>
                  {{ item.GroupName }}({{ item.MemberCount }}人)
                </div>
              </div>
              <div class="cell-r">
                <el-icon :size="25" :color="'#BFB18A'" v-if="item.isSelect">
                  <CircleCheckFilled />
                </el-icon>
              </div>
            </div>
          </div>
          <!-- <div class="line"></div> -->
        </div>

        <div class="wrap">
          <div class="left-header">
            <div class="selected">
              已选择(<span>{{ selectList.length || 0 }}</span>)
            </div>
            <el-button type="primary" @click="clearAction">清空</el-button>
            <!-- <div class="clear-all" >清空</div> -->
          </div>
          <div class="body-wrap">
            <div class="cell" v-for="(item, index) in selectList" :key="index">
              <div class="cell-l">
                <img src="@/assets/imgs/group_default.jpg" v-if="(type == 0 || type == 3)&&!item.ChatRoomHeadImg"/>
                <img src="@/assets/imgs/default_user.png" alt="" v-else-if="(type == 1|| type == 2)&&!item.ChatRoomHeadImg">
                <img :src="item.ChatRoomHeadImg" v-else/>
                <div class="title" v-if="type == 1 || type == 2">
                  {{ item.NickName }}
                </div>
                <div class="title" v-else>
                  {{ item.GroupName }}({{ item.MemberCount }}人)
                </div>
              </div>
              
              <div class="cell-r" @click="deleteAction(index)">
                <el-icon :size="25" :color="'#BFB18A'">
                  <CircleCloseFilled />
                </el-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <div class="wrap-foot">
            <el-checkbox
              v-model="allselect"
              @change="changeAll"
              v-if="type != 3"
              :disabled="!canEdit"
              >全选</el-checkbox
            >
            <div class="foot-text">
              共<span>{{ groupList.length || 0 }}</span
              >个{{ dialogTitle }}
            </div>
          </div>
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmAction">确 定</el-button>
          </div>
        </div>
      </template>
    </el-dialog>
    <GroupFieldCop ref="groupFieldRef" @onConfirm="sureAction" />
    
  </div>
</template>

<script>
import { reactive, ref, onMounted, toRef } from "vue";
import GroupFieldCop from "@/components/GroupFieldCop";
import {
  CircleCheckFilled,
  CircleCloseFilled,
  Filter,
} from "@element-plus/icons";
import {PromotionTaskAddGroup,PromotionTaskSetTagRule,PromotionTaskGetAllGroup} from "@/helper/promotion";
export default {
  components: {
    GroupFieldCop,
    CircleCloseFilled,
    CircleCheckFilled,
    Filter,
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const type = ref(0);
    const canEdit = ref(true);
    const dialogTitle = ref("群聊");
    const searchObj = reactive({
      key: "",
      taglist: [],
      tagStr: "",
      taglion: 0,
      tagIds:[]
    });
    const SID=ref("")
    const wID=ref("")
    //pageType:0 群聊  1:机器人 2.排除用户 3.群单选
    function initCop(pageType = 0, arr = [],sid,tagid,edit=true) {
      dialogVisible.value = true;
      type.value = pageType;
      SID.value=sid,
      wID.value=tagid
      canEdit.value  = edit==true?true:false
      selectList.value = arr? JSON.parse(JSON.stringify(arr)): [];
      resetData();
      searchAction();
    }
    //重置
    function resetData() {
      searchObj.key = "";
      groupList.value = [];
      allselect.value = false;
      searchObj.taglist = [];
      searchObj.tagStr = [];
      searchObj.taglion = 0;
      searchObj.tagIds = [];
      
    }
    const groupFieldRef = ref(null);
    function groupFieldAction() {
      if(!canEdit.value){
        return
      }
      groupFieldRef.value.initCop(0, searchObj.taglist, searchObj.taglion);
    }
    /*************搜索****************/
    const groupList = ref([]);
    const selectList = ref([]);
    const allselect = ref(false);
    function changeName() {
      searchAction(true);
    }
    //搜索群
    function searchAction(noCompare) {
        let arr = []
        for(let item of selectList.value){
          if(Number.isInteger(item)){
            arr.push(item)
          } else {
            arr.push(item.GroupID)
          }
        }
        let param = {
          TagIDs: searchObj.tagIds || [],
          XJoin: searchObj.taglion || 0,
          Name: searchObj.key || "",
          RobotSerialNo:SID.value,
          WTagID:wID.value
        };
        let selectItemArr = []
        PromotionTaskGetAllGroup(param).then((res) => {
          console.log(res);
          groupList.value = res.List;
          res.List.forEach((item) => {
            if (arr.indexOf(item.GroupID) > -1) {
              item.isSelect = true;
              selectItemArr.push(item)
            }
          });
          selectList.value = noCompare?selectList.value:selectItemArr
          checkAllSelect()
        });
    
    }

    /*************点击事件****************/
    //选择、
    function selectAction(index) {
      if(!canEdit.value){
        return
      }
      let item = groupList.value[index];
      if (item.isSelect) {
        let tag = -1;
        selectList.value.forEach((v, key) => {
          if (v.GroupID == item.GroupID) {
            tag = key;
            return false;
          }
        });
        if (tag > -1) {
          selectList.value.splice(tag, 1);
        }
        item.isSelect = false;
        checkAllSelect()
      } else {
        item.isSelect = true;
        selectList.value.push(item);
        checkAllSelect()
        
      }
    }

    function checkAllSelect(){
      let flag = false
        for(let item of  groupList.value){
          if(!item.isSelect){
              flag = true
              break
          }
        }
        if(flag){
           allselect.value = false;  
        } else {
          allselect.value = true;  
        }
    }

    //删除
    function deleteAction(index) {
      if(!canEdit.value){
        return
      }
      let item = selectList.value[index];
      groupList.value.forEach((ele) => {
        if (ele.GroupID == item.GroupID) {
          ele.isSelect = false;
          return false;
        }
      });
      allselect.value = false;
      selectList.value.splice(index, 1);
    }

    //清空
    function clearAction() {
      if(!canEdit.value){
        return
      }

      for (let item of groupList.value){
        item.isSelect  = false
      }
      selectList.value =[]
      allselect.value = false;
    }

    //取消全选
    function cancelAllSelect(){
      let arr = []
      for (let item of groupList.value){
        item.isSelect  = false
        arr.push(item.GroupID)
      }

      let selectArr = []
      for(let item of selectList.value){
        if(arr.indexOf(item.GroupID)<=-1){
          selectArr.push(item)
        }
      }
      selectList.value = selectArr;
      allselect.value = false;
    }

    //全选
    function changeAll(ent) {
      if(!canEdit.value){
        return
      }
      if(groupList.value&&groupList.value.length<=0){
        allselect.value = false;
        return
      }
      if (!allselect.value) {
        cancelAllSelect();
      } else {
        let arr = selectList.value.map(item=>item.GroupID)
        groupList.value.forEach((item) => {
          if(arr.indexOf(item.GroupID)<=-1){
            selectList.value.push(item);
          }
          item.isSelect = true;
        });
      }
    }

    //标签条件
    function sureAction(type, arr) {
      if (type == 0) {
        searchObj.taglist = arr;
        let tags = [];
        let tagIDs = [];
        arr.forEach((item) => {
          tags.push(item.TagName);
          tagIDs.push(item.ID);
        });
        searchObj.tagStr = tags.join(",");
        searchObj.taglion = 0;
        searchObj.tagIds = tagIDs;
      } else if (type == 1) {
        searchObj.taglist = arr;
        let tags = [];
        let tagIDs = [];
        arr.forEach((item) => {
          tags.push(item.TagName);
          tagIDs.push(item.ID);
        });
        searchObj.tagStr = tags.join(",");
        searchObj.taglion = 1;
        searchObj.tagIds = tagIDs;
      } else if (type == -1) {
        searchObj.taglist = [];
        searchObj.tagStr = "无任何标签";
        searchObj.taglion = -1;
        searchObj.tagIds = [];
      }
      searchAction(true)
    }

    //确认
    function confirmAction() {
      if(!canEdit.value){
        return
      }
      //  let param={
      //   RobotSerialNo:SID.value,
      //   GroupIDs:selectList.value.map(item=>item.GroupID)
      // }
      // PromotionTaskAddGroup(param).then(res=>{  
      //   dialogVisible.value = false;
      //   context.emit("onConfirm",selectList.value);
      // })
      let param={
        WTagIDs:[wID.value],
        GroupIDs:selectList.value.map(item=>item.GroupID),
        RobotSerialNo:SID.value,
        Type:2
      }
      PromotionTaskSetTagRule(param).then(res=>{
        dialogVisible.value = false;
        context.emit("onConfirm");
      })
    }

    return {
      SID,
      dialogVisible,
      initCop,
      confirmAction,
      searchObj,
      groupList,
      selectList,
      selectAction,
      deleteAction,
      clearAction,
      allselect,
      changeAll,
      groupFieldAction,
      groupFieldRef,
      sureAction,
      type,
      dialogTitle,
      changeName,
      searchAction,
      resetData,
      checkAllSelect,
      canEdit,
      wID,
      cancelAllSelect
    };
  },
};
</script>
<style lang='scss' scoped>
.wrap-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.wrap {
  width: 50%;
  max-height: 500px;
  position: relative;
  // border: 1px solid #ccc;
  // border-radius: 10x;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0 5px;
  // box-shadow: -1px 1px 10px #888888;

  display: flex;
  flex-direction: column;
  .left-header {
    width: 100%;
    height: 40px;
    // margin: 0 5px 0px;
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 14px;
    flex-shrink: 0;
    // box-sizing: border-box;
    border-bottom: 1px dashed #ccc;

    .header-group {
      width: 140px;
      height: 28px;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px;
      box-sizing: border-box;
      font-size: 12px;
      color: #999999;

      display: flex;
      justify-content: space-between;

      i {
        margin-right: 5px;
      }

      div {
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      color: $color-common;
    }
    .clear-all {
      color: $color-common;
    }
  }

  .body-wrap {
    width: 90%;
    margin: 0 auto;
    overflow: auto;
    font-size: 14px;
    color: #000;

    .cell {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .cell-l {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
          margin-left: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .cell-r {
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line {
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #f2f2f2;
  }
}
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .wrap-foot {
    width: 200px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    font-size: 14px;
    // margin-top: 10px;
    padding: 0 20px;

    .foot-text {
      margin-left: 10px;
    }
    span {
      color: $color-common;
    }
  }
}
</style>
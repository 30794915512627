<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
        <div>
            <span>群聊名称:</span>
            <el-input v-model="searchOption.key" placeholder="请输入群聊名称"></el-input>
        </div>
        <div>
            <span>状态:</span>
            <el-select v-model="searchOption.status" value-key="RobotID" placeholder="全部">
              <el-option v-for="item in statusList" :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-space>
        <div>
            <el-button type="primary" @click="searchAction">搜索</el-button>
            <el-button @click="resetAction">重置</el-button>
          </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box" >
        <div class="list">
            <div class="list-title">
            <div>助手名称:{{groupInfo.RobotName}}</div>
            <div>当前共<span>{{pageObj.TotalRow||0}}</span>个群</div>
            <div>入群顺序:{{groupInfo.OrderType==1?'顺序':groupInfo.OrderType==2?'随机':''}}</div>
            <div>群人数上限:{{groupInfo.MaxMemberCount}}</div>
		    </div>
        <div style="padding:0 10px">
          <el-button type="primary" @click="batchAdd">批量操作</el-button>
          <el-button type="primary" @click="addRule">入群顺序</el-button>
          <!-- <el-button type="primary" @click="addGroup">加群</el-button> -->
        </div>
		</div>
      <el-table :data="tableData" style="width: 100%" border  id="dragTable">
        <el-table-column label="顺序" width="60" align="center">
          <template #default="scope">
            <img class="dragImg" src="@/assets/imgs/can_drag.png" alt="">
          </template>
        </el-table-column>
        <el-table-column label="群聊名称" prop="ChatRoomName"></el-table-column>
        <el-table-column prop="IsOpen" label="是否开群">
          <template #default="scope">
            <div>{{scope.row.IsOpen==1?'是':'否'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="ChatRoomQrCode" label="二维码">
          <template #default="scope">
            <el-popover placement="left" trigger="hover">
              <img :src="scope.row.ChatRoomQrCode" style="width: 150px; height: 150px"/>
              <template #reference>
                <img :src="scope.row.ChatRoomQrCode" style="height: 50px; width: 50px"/>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="UserCount" label="当前群人数" width="170"></el-table-column>
        <el-table-column prop="" label="入群标签" >
          <template #default="scope">
            <div class="cell-wrap">
              <div >
                <el-tag  effect="plain" class="tag-cell" v-for="(item,index) in scope.row.TagList" :key="index">{{item.TagName}}</el-tag>
              </div>
              <el-icon :size="20" :color="'#BFB18A'" @click="addTags(scope.row)">
                <Edit />
              </el-icon>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="PromotionStatus" label="状态" width="100"></el-table-column>
        <el-table-column label="操作" width="240">
            <template #default="scope">
              <div class="action-wrap">
                <el-switch v-model="scope.row.Status" active-color="#13ce66" @change="changeSwitch(scope.row)"/>
              </div>
            </template>
        </el-table-column>
      </el-table>
      <div class="foot-wrap">
        <!-- <TableFoot :pagination="pageObj"  @changePage="changePage" @changeSize="changeSize" /> -->
      </div>
      <AddDialogCop ref="addgroupRef" @onConfirm="sureGroupAddAction"/>
      <AddTagCop ref="addtagRef" @onConfirm="sureTagsAction"/>
      <AddTagField ref="addTagsFieldRef" @onConfirm="sureTagFieldAction"/>
      <GroupAddSelectCop ref="groupSelectDialog" @onConfirm="chooseGroupAction" />
    </div>
  </div>
</template>
<script>
import { reactive,ref,onMounted,nextTick} from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox,ElMessage } from 'element-plus';
import GroupAddSelectCop from "@/components/GroupAddManager/GroupAddSelectCop";
import AddDialogCop from '@/components/GroupAddManager/AddDialogCop'
import AddTagCop from '@/components/GroupAddManager/AddTagCop'
import AddTagField from '@/components/GroupAddManager/AddTagField'
import { Edit } from '@element-plus/icons';
import {PromotionTaskGetDetail,PromotionTaskSetStatus,PromotionTaskAddGroup,PromotionTaskSort} from '@/helper/promotion'
import Tool from '@/assets/js/tools'
import Sortable from 'sortablejs'
export default {
  name: "groupadd",
  components: {
      // TableFoot,
      AddDialogCop,
      AddTagCop,
      GroupAddSelectCop,
      AddTagField,
      Edit
    },
  setup() {
    const router = useRouter();
    const SID = useRoute().query.SID;
    const tableData = ref([]);
    const addgroupRef=ref(null)
    const addTagsFieldRef=ref(null)
    const addtagRef=ref(null)
    const groupSelectDialog=ref(null)
    onMounted(()=>{
      searchAction()
      rowDrop()
    })

    //处理群列表请求参数
    function dealwithRequestData(){
      return {
          Name:searchOption.key||"",
          RobotSerialNo:SID,
          Type:searchOption.status
      }
    }

    /******************点击事件*********************/
    function addTags(row){
      addTagsFieldRef.value.initCop(0,SID,row.GroupID,row.TagList)
    }
    function batchAdd(){
      addtagRef.value.initCop(SID)
    }
    function changeSwitch(item){
      let param={
        ChatRoomSerialNo:[item.ChatRoomSerialNo],
        RobotSerialNo:[SID],
        Switch:item.Status?1:0
      }
      PromotionTaskSetStatus(param).then(res=>{
        searchAction()
      }).catch(err=>{
        item.Status=false
      })
    }

    //添加规则
    function addRule(){
      addgroupRef.value.initCop(SID,groupInfo)
    }
    //规则确认
    function sureGroupAddAction(row){
      searchAction()
    }

    //添加群
    function addGroup(){
      groupSelectDialog.value.initCop(0,tableData.value,SID);
    }

    //群确定
    function chooseGroupAction(arr){
      searchAction()
    }
    //单个群打标签
    function sureTagFieldAction(){
       searchAction()
    }
    //批量
    function sureTagsAction(){
      searchAction()
    }
    /**********************搜索***************************/
    const statusList = [
      { label: "全部", value: 0 },{ label: "引流中", value: 1 },
      { label: "待引流", value: 2 },{ label: "已满", value: 9 }
    ]
    const searchOption = reactive({
        key:"",
        status:0
    })
    const groupInfo=reactive({
      RobotName:"",
      OrderType:1,
      MaxMemberCount:0
    })
    //执行搜索
    function searchAction(){
      PromotionTaskGetDetail(dealwithRequestData()).then(res=>{
        pageObj.TotalRow=res.TotalCount
        groupInfo.RobotName=res.RobotName
        groupInfo.OrderType=res.OrderType
        groupInfo.MaxMemberCount=res.MaxMemberCount
        tableData.value=res.List
        tableData.value.forEach(item=>{
          item.Status=item.Status==1?true:false
          item.PromotionStatus=changePromotionStatus(item.PromotionStatus)
        })
      })
    }
    //转换状态
    function changePromotionStatus(status){
      if (status == 0) {
        return "已关闭";
      } else if (status == 1) {
        return "引流中";
      } else if (status == 2) {
        return "待引流";
      } else if (status == 9) {
        return "已满";
      } 
    }
    //重置条件
    function resetAction(){
        searchOption.key = '';
        searchOption.status = 0;
        searchAction();
    }


    /**************拖拽******************/
     // 行拖拽
    function rowDrop () {
        const tbody = document.querySelector('#dragTable tbody');
        Sortable.create(tbody, {
            draggable: ".el-table__row",
            handle:".dragImg",
            onEnd ({ newIndex, oldIndex }) {
                tableData.value.splice(newIndex, 0,tableData.value.splice(oldIndex, 1)[0]);
                var newArray = tableData.value.slice(0);
                tableData.value = [];
                nextTick(()=>{
                  tableData.value = newArray;
                })
                let indexList=newArray.map(item=>item.ChatRoomSerialNo)
                let param={
                  RobotSerialNo:SID,
                  NewList:indexList
                }
                PromotionTaskSort(param).then(res=>{
                    searchAction()
                })
            }
        });
       
    }
    /****************TableFoot*******************/
    const pageObj = reactive({
        TotalRow: 0,
        PageIndex: 1,
        PageSize: 10,
    })
    //页码改变
    function changePage(index) {
        pageObj.PageIndex = index;
        searchAction();
    };
    function changeSize(size) {
        pageObj.PageSize = size;
        pageObj.PageIndex = 1;
        searchAction();
    }

    return {
      addTags,
      tableData,
      changeSwitch,
      addgroupRef,
      searchOption,
      statusList,
      addtagRef,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      addGroup,
      pageObj,
      dealwithRequestData,
      rowDrop,
      addRule,
      sureGroupAddAction,
      groupSelectDialog,
      chooseGroupAction,
      SID,
      batchAdd,
      groupInfo,
      addTagsFieldRef,
      sureTagFieldAction,
      sureTagsAction
    };
  },
};
</script>
<style lang="scss" scoped>
.action-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    img{
      width: 15px;
      height: 13px;
      margin-left: 2px;
    }

}



.foot-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin-left: 3px;

        .selectAll{
            margin: 0 10px;
        }
    }
}
.list{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.list-title div{
    margin-right: 60px;
}
.list-title {
    font-size: 14px;
}
.list-title  div span{
  color:$color-common;
}
.dragImg{
  width: 30px;
  height: 30px;
}
.cell-wrap{
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
      
}
.tag-cell{
  color:$color-common !important;
  border-color: $color-common !important;
  background-color: #fff !important;
  border-radius: 10px;
  margin-right: 8px;
}
</style>
<template>
  <div class="container">
    <el-dialog title="设置入群规则" width='600px' v-model="dialogVisible">

        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="ruleForm">
            <el-form-item label="入群顺序" prop="OrderType">
                <el-select
                    v-model="ruleForm.OrderType"
                    value-key="DynamicQRCatalogSID"
                    placeholder="请选择分组"
                >
                  <el-option v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="群人数上限" prop="MaxMemberCount">
                <el-input type="number" v-model="ruleForm.MaxMemberCount" placeholder="请输入群人数上限" oninput="value=value.replace(/[^\d]/g,'')" style="width: 200px"/>
            </el-form-item>
        </el-form>

        <template #footer>
              <div class="dialog-footer">
                 <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                 <el-button @click="dialogVisible = false">取 消</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref } from "vue";
import {PromotionTaskSetRule} from '@/helper/promotion'
import { ElMessageBox,ElMessage } from 'element-plus';
import _ from "lodash";
export default {
  name: "AddDialogCop",
  setup(props, context) {
    const dialogVisible = ref(false);
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      OrderType: "",
      MaxMemberCount: ""
    });
    const RobotSerialNo=ref("")
    const rules = reactive({
      OrderType: [
        { required: true, message: "请选择入群顺序", trigger: "change" },
      ],
      MaxMemberCount: [
        { required: true, message: "请填写群人数上限", trigger: "blur" },
      ]
    });
    const statusList = [
      { label: "顺序", value: 1 },{ label: "随机", value: 2 }
    ]
    
    onMounted(()=>{})
    
    function initCop(sid,item) {
      console.log(item)
      if(sid){
        if(item.OrderType==0){
          ruleForm.OrderType=''
          ruleForm.MaxMemberCount=item.MaxMemberCount
        }else{
          ruleForm.OrderType=item.OrderType
          ruleForm.MaxMemberCount=item.MaxMemberCount
        }
        
      }
      RobotSerialNo.value=sid
      dialogVisible.value = true;
    }

   
    /*****************点击事件*********************/
     //确定表单
    const submitForm = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
          await form.validate();
          let param={
            RobotSerialNo:RobotSerialNo.value,
            OrderType:ruleForm.OrderType,
            MaxMemberCount:ruleForm.MaxMemberCount
          }
          console.log(param)
          PromotionTaskSetRule(param).then(res=>{
            ElMessage({
              type: "success",
              message: "提交成功",
            });
            dialogVisible.value = false;
            context.emit("onConfirm",param);
          })
        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );
    return {
      initCop,
      dialogVisible,
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      statusList,
      RobotSerialNo
    };
  }
};
</script>
<style lang='scss' scoped>
.ruleForm {
  // width: 400px;
  margin: 20px 20px 0;
  p {
    font-size: 12px;
    color: #bbb;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}
</style>
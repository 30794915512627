<template>
  <div class="container">
    <el-dialog title="选择标签" width="60%" v-model="dialogVisible">
      <el-input
        placeholder="请输入企业标签"
        suffix-icon="el-icon-search"
        v-model="searchObj.key"
        @change="changeInputAction"
      ></el-input>

      <div class="wrap">
        <div class="group-title" v-for="(item, index) in tagList" :key="index">
          <!-- <el-tag  effect="dark"></el-tag> -->
          <p>{{ item.GroupName }}</p>
          <div class="cell">
            <div
              v-for="(item2, index2) in item.Tag"
              :key="index2"
              @click="selectAction(index, index2)"
            >
              <el-tag style="cursor:pointer"
                effect="dark"
                type="success"
                size="small"
                v-if="item2.isSelect"
                >{{ item2.TagName }}
              </el-tag>
              <el-tag  style="cursor:pointer" effect="plain" type="success" size="small" v-else>
                {{ item2.TagName }}
              </el-tag>
            </div>

            <!-- <p :class="item2.isSelect?'selected':''" v-for="(item2,index2) in item.list" :key="index2" @click="selectAction(index,index2)">{{item2.title}}</p> -->
          </div>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { GetSimpleTagList } from "@/helper/robot";
import { PromotionTaskSetTagRule } from "@/helper/promotion";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  setup(props, context) {
    const dialogVisible = ref(false);
    const type=ref(0)
    const searchObj = reactive({
      key: "",
    });
    const robotID=ref("")
    const groupID=ref("")
    //0 指定群打标签 1 添加标签
    function initCop(pageType=0,sid,groupId,arr) {
      type.value = pageType;
      dialogVisible.value = true;
      robotID.value=sid
      groupID.value=groupId
      resetData();
      searchAction(arr);
    }

    const tagList = ref([]);
    function searchAction(arr) {
      GetSimpleTagList({RobotSerialNo:robotID.value}).then((res) => {
        sourceList.value = res.List;
        tagList.value = res.List;
        if(type.value==0){
          if (arr&&arr.length>0) {
            res.List.forEach((item) => {
              item.Tag.forEach((item2) => {
                let arrGroup=arr.map(item2=>item2.TagID)
                if (arrGroup.indexOf(item2.ID) > -1) {
                  item2.isSelect = true;  
                }
              });
            });
          }
        }
      });
    }
    //重置
    function resetData() {
      searchObj.key = "";
      sourceList.value = [];
      tagList.value = [];
    }
    /***********点击事件*************/
    const sourceList = ref([]); //原始数据

    //筛选
    function changeInputAction() {
      if(searchObj.key.length>0){
        let arr=[]
        for(let item of sourceList.value){
          let tagObj = JSON.parse(JSON.stringify(item))
          tagObj.Tag = []
          for (let tag of item.Tag) {
            if(tag.TagName.toLowerCase().indexOf(searchObj.key.toLowerCase())>-1){
              tagObj.Tag.push(tag)
            }
          }
          if(tagObj.Tag.length>0){
            arr.push(tagObj)
          }
        }
        tagList.value = arr;
      }else{
        tagList.value=sourceList.value
      }
      
        
    }
    //选中
    function selectAction(index, index2) {
      let item = tagList.value[index].Tag[index2];
      item.isSelect = item.isSelect ? false : true;
      // item.isSelect=true
      // tagList.value.forEach((item,index5)=>{
      //     item.Tag.forEach((item2,index3)=>{
      //       if(index3!=index2){
      //         item2.isSelect=false
      //       }
      //     })
      // })
    }
    //确认
    function confirmAction() {
      let selectlist = [];
      tagList.value.forEach((item) => {
        if (item.Tag && item.Tag.length > 0) {
          item.Tag.forEach((v) => {
            if (v.isSelect) {
              selectlist.push(v.ID);
            }
          });
        }
      });
      if(selectlist.length<=0){
        ElMessage({
            type: "warning",
            message: "请选择标签",
        });
        return false
      }
      let param={
        WTagIDs:selectlist,
        GroupIDs:type.value==0?[groupID.value]:[],
        RobotSerialNo:robotID.value,
        Type:1
      }
      PromotionTaskSetTagRule(param).then(res=>{
        dialogVisible.value = false;
        context.emit("onConfirm", selectlist);
      })
    }

    return {
      dialogVisible,
      initCop,
      confirmAction,
      searchObj,
      searchAction,
      tagList,
      sourceList,
      selectAction,
      resetData,
      changeInputAction,
      robotID,
      groupID,
      type
    };
  },
};
</script>
<style lang='scss' scoped>
.wrap-fliter {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  span {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-shrink: 0;
    letter-spacing: 0;
    line-height: 14px;
    font-size: 14px;
  }
  .radio-group {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    box-sizing: border-box;

    :deep() .el-radio__label {
      padding-left: 5px;
    }
  }
}

.wrap {
  width: 100%;
  max-height: 400px;
  overflow: auto;

  display: flex;
  flex-direction: column;

  .group-title {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    p {
      margin: 5px 0;
      padding-bottom: 5px;
      font-size: 13px;
      color: $color-text;
      border-bottom: 1px dashed $color-text;
    }
  }
  .cell {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    div {
      margin: 5px 10px 5px 0;
      border-radius: 3px;
    }
  }
}

:deep() .el-tag--plain {
  border-color: $color-text;
  color: $color-text;
}

:deep() .el-tag--dark {
  background-color: $color-common;
  border-color: $color-common;
}
</style>